import { useSelector } from 'react-redux'

import { RootState } from '../../store/store';
import generateSignature from '../../utils/authorization/generateSignature';

export interface ParamsModel {
    organisationCode: string | undefined;
    isProtech: boolean | undefined;
    isLimited: boolean | undefined;
    userName: string | undefined;
    loginType: string;
    connexionTimeStamp: number;
    signature: string;
}

const useIframeParams = () => {

    const currentUserState = useSelector((store: RootState) => store.USER.currentUser);
    const currentOrganizationState = useSelector((store: RootState) => store.ORGANIZATION.currentOrganization);
    let numberAccountType: string = ""

    switch (currentUserState?.accountType) {
        case "Admin":
            numberAccountType = "1"
            break;
        case "User":
            numberAccountType = "2"
            break;
        default:
            numberAccountType = "3"
            break;
    }

    let currentTimeStamp: number = Date.now();

    const params: ParamsModel = {
        organisationCode: currentOrganizationState?.code,
        isProtech: currentUserState?.isSuperAdministrator,
        isLimited: currentOrganizationState?.isCTonlineLimited,
        userName: currentUserState?.login,
        loginType: numberAccountType,
        connexionTimeStamp: currentTimeStamp,
        signature: generateSignature([numberAccountType, currentOrganizationState?.code, currentOrganizationState?.isCTonlineLimited, currentUserState?.isSuperAdministrator, currentUserState?.login, currentTimeStamp])
    }

    return params;
}

export default useIframeParams;
import md5 from "md5";

const generateSignature = (params: (string | number | boolean | undefined)[]): string => {
    // sharedAuthenticationSecretKey
    params.push(
        "1DA5C10E792D44F0A970FA147CE8559C6D9AF4B12625C5839C001962E85D170C5F51D310F10007DABBCDF73ED22167363F39C041BB5171073FDBBCAD64BE5512"
    );

    let stringedParams: string = "";
    params.forEach((param) => {
        stringedParams += param
    });

    return md5(stringedParams.toUpperCase()).slice(0, -1);
};

export default generateSignature;
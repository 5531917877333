import { Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Location, NavigateFunction, useLocation, useNavigate } from "react-router-dom";

import { RootState } from "../../store/store";
import { CONFIG_CTO } from "../../data/config.CTO"
import { UserModel } from "../../models/UserModel";
import CTonlineLogo from "../../assets/images/ctonline-circle-logo.png";

import ProfileMenu from "../auth/ProfileMenu";
import NavMenu from "./NavMenu";
import SettingsList from "./SettingsList";

export interface HeaderProps {
    showDrawer: boolean,
    setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>,
    setShowModal?: React.Dispatch<React.SetStateAction<boolean>>,
    marginRight?: string | number,
    unreadNewsNumber: number
}

const Header = ({ showDrawer, unreadNewsNumber, setShowDrawer, setShowModal, marginRight }: HeaderProps) => {

    const location: Location = useLocation();
    const navigate: NavigateFunction = useNavigate()
    let titlePage: string | undefined;

    const currentUserState: UserModel | undefined = useSelector((store: RootState) => store.USER.currentUser)
    const currentOrganizationState = useSelector((store: RootState) => store.ORGANIZATION.currentOrganization)

    switch (location.pathname) {
        case `/${CONFIG_CTO.CALENDAR_PATH}`:
            titlePage = CONFIG_CTO.CALENDAR
            break;
        case `/${CONFIG_CTO.DASHBOARDS_PATH}`:
            titlePage = CONFIG_CTO.DASHBOARDS
            break;
        case `/${CONFIG_CTO.DOCUMENTS_PATH}`:
            titlePage = CONFIG_CTO.DOCUMENTS
            break;
        case `/${CONFIG_CTO.TOOLS_PATH}`:
            titlePage = CONFIG_CTO.TOOLS
            break;
        case `/${CONFIG_CTO.FLEET_PATH}`:
            titlePage = CONFIG_CTO.FLEET
            break;
        case `/${CONFIG_CTO.CENTER_PATH}`:
            titlePage = `Paramètres du centre`
            break;
        case `/${CONFIG_CTO.ORGANIZATION_PATH}`:
            titlePage = `Paramètres`
            break;
        case `/${CONFIG_CTO.ACCOUNT_PATH}`:
            titlePage = `${currentUserState?.firstName} ${currentUserState?.lastName}`
            break;
        default:
            titlePage = ""
            break;
    }

    return (
        <header>
            <Stack marginRight={marginRight} sx={{
                width: "100%", height: "auto", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "0 0.5rem", backgroundColor: "white", boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px"
            }}>
                <Stack flexDirection="row" alignItems="center" gap={1}>
                    {!currentOrganizationState?.isCTonlineLimited && (
                        <NavMenu />
                    )}
                    <img src={CTonlineLogo} width={45} alt="Icône CTonline" style={{ cursor: "pointer" }} onClick={() => navigate('/')} />
                    <Stack flexDirection={{ xs: "column", sm: "row" }} gap={0.5}>
                        <Typography fontWeight="bold">{currentOrganizationState?.name}</Typography>
                        <Typography>/ {titlePage}</Typography>
                    </Stack>
                </Stack>
                <Stack flexDirection="row" alignItems="center" gap={0.5}>
                    <SettingsList unreadNewsNumber={unreadNewsNumber} showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
                    <ProfileMenu setShowModal={setShowModal} />
                </Stack>
            </Stack>
        </header>
    )
}

export default Header
import { useDispatch } from "react-redux"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"

import { AppDispatch } from "../../store/store"
import { CenterModel } from "../../models/CenterModel"
import { UserModel } from "../../models/UserModel"
import { CONFIG_CTO } from "../../data/config.CTO"
import { selectCurrentUser } from "../../store/user/user-slice"

interface Props<Item extends CenterModel | UserModel> {
    variant: "centerSelect" | "profileSelect";
    label: string;
    arrayList: Item[];
}

const renderMenuItem = (item: CenterModel | UserModel, variant: string) => {
    // Use a type assertion to inform TypeScript about the type of the item
    if (variant === "centerSelect") {
        return (
            <MenuItem key={(item as CenterModel).pid} value={(item as CenterModel).centerId}>
                {(item as CenterModel).name}
            </MenuItem>
        );
    }
    if (variant === "profileSelect") {
        return (
            <MenuItem key={(item as UserModel).pid} value={(item as UserModel).pid}>
                {(item as UserModel).login} - {(item as UserModel).lastName}
            </MenuItem>
        );
    }
};

const ListSelector = ({ arrayList, variant, label }: Props<CenterModel | UserModel>) => {

    const navigate: NavigateFunction = useNavigate()
    const dispatch: AppDispatch = useDispatch()

    const handleChange = (event: SelectChangeEvent) => {
        if (variant === "centerSelect") {
            navigate(`/${CONFIG_CTO.CENTER_PATH}`, { state: event.target.value })
        } else if (variant === "profileSelect") {
            dispatch(selectCurrentUser(event.target.value, arrayList))
        }
    }

    return (
        <FormControl sx={{ width: '80%', mt: 2 }}>
            <InputLabel>{label}</InputLabel>
            <Select label={label} value={""} onChange={handleChange}>
                {arrayList.map((item) => renderMenuItem(item, variant))}
            </Select>
        </FormControl>
    )
}

export default ListSelector
import { AxiosResponse } from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { api } from "../../utils/api";
import { CONFIG_API } from "../../data/config.API";
import { AppDispatch } from "../store";
import { CenterModel } from "../../models/CenterModel";
import { APIResponsesModel } from "../../models/ApiResponseModel";
import { AccountType } from "../../models/UserModel";

export interface InitialStateModel {
    organizationCenters: CenterModel[] | undefined
    loading: boolean;
    error: string | null;
}

const initialState: InitialStateModel = {
    organizationCenters: undefined,
    loading: true,
    error: null
}

// SLICE
export const centerSlice = createSlice({
    name: "centerSlice",
    initialState,
    reducers: {
        setCenters: (currentSlice, action) => {
            currentSlice.organizationCenters = action.payload
        },
        setLoading: (currentSlice, action) => {
            currentSlice.loading = action.payload
        },
        setError: (currentSlice, action) => {
            currentSlice.error = action.payload
        }
    }
})

export const { setCenters, setLoading, setError } = centerSlice.actions;

// ACTIONS
export const fetchUserCenters = (organizationPid: string, accountType: AccountType, externalUserId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
        await api.get(`/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.CENTER}/${accountType}/${CONFIG_API.EXTERNAL_ACCOUNT}/${externalUserId}`).then((response: AxiosResponse<APIResponsesModel<CenterModel>>) => {
            dispatch(setLoading(false));
            if (response.data.items && response.data.items.length > 0) {
                dispatch(setCenters(response.data.items));
            } else {
                dispatch(setError("Aucun centre n'est attribué à l'utilisateur."))
            }
        })
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(setError(error));
    }
}
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { faXmark } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Checkbox, FormControl, FormControlLabel, Paper, Stack, Typography } from '@mui/material'

import { AppDispatch, RootState } from "../../store/store";
import { AllNewsModel } from '../../models/NewsModel'
import { hideMessage, updateReadStatus } from "../../store/news/news-slice";
import useDateFormat from '../../hooks/common/useDateFormat'
import CTonlineLogo from "../../assets/images/ctonline-small-logo.png";


interface Props {
    message: AllNewsModel,
    icon: string,
    iconSize: string,
    iconTitle: string,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
}

const MessageModal = ({ message, icon, iconTitle, iconSize, setShowModal }: Props) => {

    const [checked, setChecked] = useState<boolean>();
    const dispatch: AppDispatch = useDispatch();

    const currentUserState = useSelector((store: RootState) => store.USER.currentUser);
    const currentOrganizationState = useSelector((store: RootState) => store.ORGANIZATION.currentOrganization);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    }

    const onValidate = () => {
        if (checked !== undefined && message.hideMessage !== checked) {
            if (currentOrganizationState && currentUserState) {
                dispatch(hideMessage(currentOrganizationState.pid, currentUserState.accountType, currentUserState.externalUserId, message.id, checked))
            }
        }
        closeModal();
    }

    const closeModal = () => {
        if (currentOrganizationState && currentUserState && !message.isMessageRead) {
            dispatch(updateReadStatus(currentOrganizationState.pid, currentUserState.accountType, currentUserState.externalUserId, message.id, "message"))
        }
        setShowModal(false)
    }

    return (
        <Stack height={"100vh"} justifyContent={"center"} alignItems={"center"}   >
            <Paper
                sx={{
                    width: { xs: "20rem", sm: "auto" },
                    height: "auto",
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    gap: 1,
                    padding: 3,
                    m: "10rem",
                    overflowY: "hidden"
                }}
            >
                <FontAwesomeIcon icon={faXmark} size="lg" style={{ cursor: "pointer", position: "absolute", top: 10, right: 20 }} onClick={() => closeModal()} />
                <img src={CTonlineLogo} width={175} alt="Icône CTonline" style={{ margin: "0 auto 1rem" }} />
                <Stack gap={1} p={3} m="auto" sx={{ bgcolor: "whitesmoke", border: "1px solid #ea6927", borderRadius: "1rem", overflowY: "auto" }}>
                    <Stack flexDirection={"row"} alignContent={"center"}>
                        <img src={icon} width={iconSize} title={iconTitle} alt={iconTitle} style={{ marginRight: "0.4rem" }} />
                        <Typography variant="subtitle2" fontSize={"0.75rem"} sx={{ paddingTop: message.type === 5 ? "0.9rem" : "0.3rem" }}>
                            Envoyé le {useDateFormat(message.sendDate)}
                        </Typography>
                    </Stack>
                    <Typography>{message.text}</Typography>
                </Stack>
                <Stack>
                    <FormControl sx={{ m: "auto" }}>
                        <FormControlLabel label="Ne plus afficher ce message dans les actualités"
                            sx={{ fontWeight: "bold" }}
                            control={
                                <Checkbox defaultChecked={message.hideMessage} onChange={handleChange}
                                />
                            }
                        />
                        <Stack flexDirection={"row"} m="auto" gap={1}>
                            <Button variant="contained" onClick={() => onValidate()}>Valider</Button>
                            <Button variant="outlined" onClick={() => closeModal()}>Annuler</Button>
                        </Stack>
                    </FormControl>
                </Stack>
            </Paper >
        </Stack >)
}

export default MessageModal
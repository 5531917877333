import { ReactNode, useState, useEffect } from "react"
import { CircularProgress, Stack, SwipeableDrawer, Typography } from "@mui/material";
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from "@azure/msal-react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-light-svg-icons";

import { RootState } from "../../store/store";
import { CONFIG_API } from "../../data/config.API";
import useWindowWidth from "../../hooks/layout/useWindowWidth";

import Header from './Header'
import CustomModal from "./CustomModal";
import AuthenticationPage from "./AuthenticationPage";
import NewsContainer from "../news/NewsContainer";

type Props = {
    children: ReactNode,
    msalInstance: PublicClientApplication,
}

const Layout = ({ children, msalInstance }: Props) => {

    const userState = useSelector((user: RootState) => user.USER.currentUser);
    let isDesktop = useWindowWidth();
    const [showDrawer, setShowDrawer] = useState<boolean>(userState?.accountType === CONFIG_API.CLIENT ? false : isDesktop);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [unreadNewsNumber, setUnreadNewsNumber] = useState<number>(0);

    const drawerWidth = "300px"

    // DISABLE DRAWER ON MOBILE
    useEffect(() => {
        if (userState !== undefined && userState?.accountType !== CONFIG_API.CLIENT) {
            setShowDrawer(isDesktop)
        }
    }, [isDesktop, userState])

    return (
        <MsalProvider instance={msalInstance}>
            <AuthenticationPage>
                <Stack height="100vh" marginRight={showDrawer ? drawerWidth : "0"} >
                    <Header unreadNewsNumber={unreadNewsNumber} showDrawer={showDrawer} setShowDrawer={setShowDrawer} setShowModal={setShowModal} marginRight={showDrawer ? drawerWidth : "0"} />
                    <SwipeableDrawer
                        anchor={"right"}
                        open={showDrawer}
                        onClose={() => setShowDrawer(false)}
                        onOpen={() => setShowDrawer(true)}
                        elevation={16}
                        hideBackdrop={true}
                        variant="persistent"
                        sx={{ position: "relative" }}
                    >
                        <Stack width={drawerWidth} margin={"auto"} sx={{ height: "100vh", overflowY: "auto" }}>
                            <Typography onClick={() => setShowDrawer(false)} sx={{ position: "absolute", top: "0", left: 250, marginTop: "1rem", marginLeft: "1rem", zIndex: 999 }} >
                                <FontAwesomeIcon icon={faXmark} size="lg" style={{ cursor: "pointer" }} />
                            </Typography>
                            <NewsContainer setUnreadNewsNumber={setUnreadNewsNumber} showNews={showDrawer} />
                        </Stack>
                    </SwipeableDrawer>
                    <Stack marginTop="-7px" sx={{ overflow: "auto" }}>
                        {children}
                    </Stack>
                </Stack>
            </AuthenticationPage>
            <CustomModal showLogo={false} open={showModal}>
                <CircularProgress />
                <Typography variant="h6" color="primary.main">Déconnexion en cours...</Typography>
            </CustomModal>
        </MsalProvider >
    )
}

export default Layout
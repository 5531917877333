import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Stack } from "@mui/material"
import { faBars, faCars, faCalendarDays, faFiles, faChartLine, faWrench, faAward, faQuestion, faBagShopping } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { RootState } from "../../store/store";
import { CONFIG_CTO } from "../../data/config.CTO";
import { CONFIG_API } from "../../data/config.API";
import { MenuItemModel } from "../common/BasicMenuItem"
import useWindowWidth from "../../hooks/layout/useWindowWidth";

import BasicMenu from "../common/BasicMenu"

const NavMenu = () => {

    const currentUserState = useSelector((store: RootState) => store.USER.currentUser);

    const menuItems = useMemo(() => {
        let items: MenuItemModel[] = [
            {
                name: CONFIG_CTO.CALENDAR,
                text: CONFIG_CTO.CALENDAR,
                icon: faCalendarDays,
                navigateTo: "/"
            },
            {
                name: CONFIG_CTO.DASHBOARDS,
                text: CONFIG_CTO.DASHBOARDS,
                icon: faChartLine,
                navigateTo: `/${CONFIG_CTO.DASHBOARDS_PATH}`
            },
            {
                name: CONFIG_CTO.QUALITY,
                text: CONFIG_CTO.QUALITY,
                icon: faAward,
                externalLink: "https://qualite.ctonline.fr/"
            },
            {
                name: CONFIG_CTO.TOOLS,
                text: CONFIG_CTO.TOOLS,
                icon: faWrench,
                navigateTo: `/${CONFIG_CTO.TOOLS_PATH}`
            },

        ]

        // ADD DOCUMENTS NAV TO MENU IF USER HAS ACCESS
        if (currentUserState?.hasDocumentAccess) {
            items.splice(2, 0,
                {
                    name: CONFIG_CTO.DOCUMENTS,
                    text: CONFIG_CTO.DOCUMENTS,
                    icon: faFiles,
                    navigateTo: `/${CONFIG_CTO.DOCUMENTS_PATH}`
                }
            )
        }
        // ADD FLEET IF USER HAS ACCESS
        if (currentUserState?.hasVehicleFleetAccess) {
            items.splice(-3, 0,
                {
                    name: CONFIG_CTO.FLEET,
                    text: CONFIG_CTO.FLEET,
                    icon: faCars,
                    navigateTo: `/${CONFIG_CTO.FLEET_PATH}`
                }
            )
        }
        // REMOVE QUALITY & TOOLS IF USER TYPE = CLIENT
        if (currentUserState?.accountType === CONFIG_API.CLIENT) {
            for (let i = items.length - 1; i >= 0; i--) {
                if (items[i].name === CONFIG_CTO.QUALITY || items[i].name === CONFIG_CTO.TOOLS) {
                    items.splice(i, 1);
                }
            }
        }
        return items;
    }, [currentUserState]);


    // ADD OR REMOVE ITEMS DEPENDING ON SCREEN SIZE
    let isDesktop = useWindowWidth();
    useEffect(() => {
        if (currentUserState?.accountType !== CONFIG_API.CLIENT) {
            if (!isDesktop) {
                menuItems.push(
                    {
                        name: CONFIG_CTO.HELP,
                        text: CONFIG_CTO.HELP,
                        icon: faQuestion,
                        externalLink: "https://protechnologies.zendesk.com/"
                    },
                    {
                        name: CONFIG_CTO.SHOP,
                        text: CONFIG_CTO.SHOP,
                        icon: faBagShopping,
                        externalLink: "https://protechnologies.shop/"
                    })
            } else {
                for (let i = menuItems.length - 1; i >= 0; i--) {
                    if (menuItems[i].name === CONFIG_CTO.HELP || menuItems[i].name === CONFIG_CTO.SHOP) {
                        menuItems.splice(i, 1);
                    }
                }
            }
        }
    }, [currentUserState, isDesktop, menuItems])

    return (
        <Stack display={{ xs: "none", md: "flex" }}>
            <BasicMenu
                positionY="bottom"
                positionX="left"
                items={menuItems}
            >
                <Stack height="100%" sx={{ cursor: "pointer" }}>
                    <FontAwesomeIcon icon={faBars} size="2x" style={{ cursor: "pointer" }} />
                </Stack>
            </BasicMenu>
        </Stack>
    )
}

export default NavMenu
import { useCallback, useMemo } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Stack } from '@mui/material'
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMsal } from "@azure/msal-react";
import { faCircleUser, faRightFromBracket, faObjectUngroup, faUsers } from '@fortawesome/pro-light-svg-icons'

import { AppDispatch, RootState } from "../../store/store";
import { MenuItemModel } from "../common/BasicMenuItem";
import { CONFIG_CTO } from '../../data/config.CTO';
import { setCurrentOrganization } from "../../store/organization/organization-slice";
import { setCurrentUser, setUsersList } from "../../store/user/user-slice";
import { setCenters } from "../../store/center/center-slice";
import { setMessages, setNews } from "../../store/news/news-slice";

import BasicMenu from '../common/BasicMenu'
import UserInfo from "./UserInfo";

type Props = {
    setShowModal?: React.Dispatch<React.SetStateAction<boolean>>,
}

const ProfileMenu = ({ setShowModal }: Props) => {

    const navigate: NavigateFunction = useNavigate()
    const dispatch: AppDispatch = useDispatch()
    const { instance } = useMsal()
    const organizationState = useSelector((store: RootState) => store.ORGANIZATION);
    const userState = useSelector((store: RootState) => store.USER);

    // LOGOUT
    const handleLogout = useCallback(async () => {
        setShowModal && (
            setShowModal(true)
        )
        localStorage.clear(); // To Clear Organization setted with Redux-persist
        await instance.logout({
            authority: process.env.REACT_APP_AZURE_BASE_URL,
            postLogoutRedirectUri: `/`,
        });
    }, [instance, setShowModal])

    // Reset CurrentOrganization in store to select an new one (reset profile, center & news states as well)
    const clearCurrentOrganization = useCallback(() => {
        dispatch(setCurrentOrganization(null))
        dispatch(setCurrentUser(undefined))
        dispatch(setUsersList(undefined))
        dispatch(setCenters(undefined))
        dispatch(setNews(undefined))
        dispatch(setMessages(undefined))
        // navigate to home page to avoid forbidden page access if the new organization is limited
        navigate("/")
    }, [dispatch, navigate])

    // Reset CurrentProfile in store to select an new one
    const clearCurrentProfile = useCallback(() => {
        dispatch(setCurrentUser(undefined))
    }, [dispatch])

    // Define a list of all potential menu items and conditions for adding them
    const potentialMenuItems = useMemo(() => [
        {
            name: CONFIG_CTO.CHANGE_ORGANIZATION,
            text: CONFIG_CTO.CHANGE_ORGANIZATION,
            icon: faObjectUngroup,
            onClick: clearCurrentOrganization,
            condition: () => (organizationState.userOrganizations && organizationState.userOrganizations?.length > 1) || (userState.currentUser?.isSuperAdministrator)
        },
        {
            name: CONFIG_CTO.CHANGE_PROFILE,
            text: CONFIG_CTO.CHANGE_PROFILE,
            icon: faUsers,
            onClick: clearCurrentProfile,
            condition: () => (userState.usersList && userState.usersList?.length > 1)
        }
    ], [clearCurrentOrganization, clearCurrentProfile, organizationState.userOrganizations, userState.currentUser?.isSuperAdministrator, userState.usersList]);

    const menuItems = useMemo(() => {

        let items: MenuItemModel[] = [
            {
                name: CONFIG_CTO.LOGOUT,
                text: CONFIG_CTO.LOGOUT,
                icon: faRightFromBracket,
                onClick: handleLogout,
            }
        ]

        // Check each potential menu item to see if its conditions are met
        potentialMenuItems.forEach(potentialItem => {
            if (potentialItem.condition()) {
                // Additional logic to avoid duplicates could also be added here after rerender
                const isItemAlreadyInMenu = items.some(item => item.name === potentialItem.name);
                if (!isItemAlreadyInMenu) {
                    // Add new item BEFORE logout item
                    items.push(potentialItem)
                }
            }
        });

        // Reverse items so that "Logout" is always at the end
        return items.reverse();
    }, [handleLogout, potentialMenuItems]);


    return (
        <BasicMenu
            positionY="bottom"
            positionX={-80}
            items={menuItems}
            beforeText={<UserInfo />}
        >
            <Stack alignItems="center" justifyContent="center" gap={1} height="100%" sx={{ cursor: "pointer" }}>
                <FontAwesomeIcon icon={faCircleUser} size="3x" />
            </Stack>
        </BasicMenu>
    )
}

export default ProfileMenu
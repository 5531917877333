import { useSelector } from 'react-redux';
import { Avatar, Divider, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import { useMsal } from '@azure/msal-react';

import { RootState } from '../../store/store'

const UserInfo = () => {

    const { accounts } = useMsal();
    const userState = useSelector((user: RootState) => user.USER.currentUser);

    // GENERATE INITIALS AVATAR FROM USER FIRST NAME & LAST NAME
    const stringAvatar = (name: string): { children: string | JSX.Element } => {
        if (userState?.firstName && userState.lastName) {
            // FIRST NAME + LAST NAME
            return {
                children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
            }
        } else if (!userState?.lastName && userState?.firstName) {
            // ONLY FIRST NAME
            return {
                children: `${name.split(' ')[0][0]}`,
            }
        } else if (!userState?.firstName && userState?.lastName) {
            // ONLY LAST NAME
            return {
                children: `${name.split(' ')[1][0]}`,
            }
        } else {
            // NEITHER FIRST NAME OR LAST NAME, SHOW USER ICON
            return {
                children: <FontAwesomeIcon icon={faUser} />
            }
        }
    }

    return (
        <>
            <Stack textAlign="center" gap={1} flexDirection="row">
                <Stack justifyContent={"center"}>
                    <Avatar {...stringAvatar(`${userState?.firstName} ${userState?.lastName}`)} sx={{ width: "3rem", height: "3rem", backgroundColor: "primary.main" }} />
                </Stack>
                <Stack>
                    <Typography fontWeight="bold">{userState?.firstName} {userState?.lastName}</Typography>
                    <Typography sx={{ wordBreak: "break-word" }} variant='subtitle2' fontSize={"0.8rem"}>{accounts[0].username}</Typography>
                </Stack>
            </Stack>
            <Divider sx={{ margin: "0.5rem 0" }} />
        </>
    )
}

export default UserInfo
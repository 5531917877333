import { CssBaseline } from "@mui/material";
import { Routes, Route } from "react-router-dom";

import { CONFIG_CTO } from "./data/config.CTO";

import Home from "./pages/Home";
import Calendar from "./pages/Calendar";
import Dashboards from "./pages/Dashboards";
import Documents from "./pages/Documents";
import Tools from "./pages/Tools";
import Fleet from "./pages/Fleet";
import Account from "./pages/Account";
import Organization from "./pages/Organization";
import Center from "./pages/Center";
import Error404 from "./components/layouts/Error404";

function App() {

  return (
    <>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path={`/${CONFIG_CTO.CALENDAR_PATH}`} element={<Calendar />} />
        <Route path={`/${CONFIG_CTO.DASHBOARDS_PATH}`} element={<Dashboards />} />
        <Route path={`/${CONFIG_CTO.DOCUMENTS_PATH}`} element={<Documents />} />
        <Route path={`/${CONFIG_CTO.TOOLS_PATH}`} element={<Tools />} />
        <Route path={`/${CONFIG_CTO.FLEET_PATH}`} element={<Fleet />} />
        <Route path={`/${CONFIG_CTO.ACCOUNT_PATH}`} element={<Account />} />
        <Route path={`/${CONFIG_CTO.ORGANIZATION_PATH}`} element={<Organization />} />
        <Route path={`/${CONFIG_CTO.CENTER_PATH}`} element={<Center />} />
        <Route path={`/${CONFIG_CTO.CONNECT_CHECK_PATH}`} element={<></>} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
}

export default App;

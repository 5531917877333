import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton, Stack, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessageLines } from "@fortawesome/pro-light-svg-icons"

import { AppDispatch, RootState } from "../../store/store";
import { fetchAllNews } from "../../store/news/news-slice";
import { AllNewsModel, MessageModel, NewsModel } from "../../models/NewsModel";

import NewsCard from "./NewsCard";

interface Props {
    showNews: boolean,
    setUnreadNewsNumber?: React.Dispatch<React.SetStateAction<number>>
}

const NewsContainer = ({ showNews, setUnreadNewsNumber }: Props) => {

    const dispatch: AppDispatch = useDispatch()

    const currentUserState = useSelector((store: RootState) => store.USER.currentUser);
    const currentOrganizationState = useSelector((store: RootState) => store.ORGANIZATION.currentOrganization);
    const skeletonList = Array.from(Array(6).keys())

    const loadNews = useCallback(() => {
        if (currentUserState && currentOrganizationState) {
            dispatch(fetchAllNews(currentOrganizationState.pid, currentUserState.accountType, currentUserState.externalUserId))
        }
    }, [currentUserState, currentOrganizationState, dispatch])

    const newsList = useSelector((store: RootState) => store.NEWS.newsList)
    const messagesList = useSelector((store: RootState) => store.NEWS.messagesList)
    const isLoading = useSelector((store: RootState) => store.NEWS.loading)
    const [allNews, setAllNews] = useState<AllNewsModel[]>([]);

    useEffect(() => {
        if (newsList && messagesList) {
            // The first 3 news from newsList
            const lastThreeNews: NewsModel[] = newsList.slice(0, 3);

            // Date from a month ago
            const oneMonthAgo = new Date();
            oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

            // Filter all messages based on date and the hideMessage flag
            let messages: MessageModel[] = messagesList
                .filter((message: MessageModel) =>
                    new Date(message.sendDate) > oneMonthAgo &&
                    !message.hideMessage
                );

            // Find the latest message of type "5"
            const lastTypeFive: MessageModel[] = messages.filter((message: MessageModel) => message.type === 5)

            // Remove all messages of type "5" ("Summary") from the initial messages array
            messages = messages.filter((message: MessageModel) => message.type !== 5);

            // Add the latest message of type "5" ("Summary") to the messages
            if (lastTypeFive.length) messages.push(lastTypeFive[0]);

            // Merge all arrays
            const combinedNews: AllNewsModel[] = [...lastThreeNews, ...messages];

            // Sort the array in reverse chronological order
            combinedNews.sort((a, b) => new Date(b.sendDate).getTime() - new Date(a.sendDate).getTime());

            setAllNews(combinedNews);
        }
    }, [newsList, messagesList]); // This function will be executed every time newsList or messagesList changes.


    useEffect(() => {
        // SET NOTIFICATION BADGE IF THERE IS AT LEAST ONE UNREAD NEWS
        if (allNews && setUnreadNewsNumber) {
            let unreadNews = allNews.filter((element) => element.readDate === null);
            setUnreadNewsNumber(unreadNews.length)
        }
    }, [allNews, setUnreadNewsNumber])

    useEffect(() => {
        if (showNews) {
            loadNews()
        }
    }, [loadNews, showNews])

    return (
        <Stack flexDirection={"column"} justifyContent="flex-start" alignItems="center" width="100%" mt="1rem" gap={1.5}>
            <Stack>
                <Typography variant="h5" color='primary.main' fontWeight={"bold"} sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }} >
                    <FontAwesomeIcon icon={faMessageLines} />
                    Actualités
                </Typography>
            </Stack>
            {
                !isLoading ? (
                    allNews && allNews?.length > 0 ? (
                        allNews.map((element) => (
                            <NewsCard key={element.id} element={element} />
                        ))
                    ) : (
                        <Typography>
                            Aucune actualité à afficher
                        </Typography>
                    )
                ) : (
                    <Stack m="auto" gap={2} alignItems={"center"}>
                        <Typography fontWeight="bold" color="primary.main">Chargement des actualités...</Typography>
                        {skeletonList.map((number) => (
                            <Stack key={number} sx={{ width: "15rem", height: "object-fit" }}>
                                <Skeleton variant="rectangular" width="15rem" height={"10rem"} />
                            </Stack>)
                        )}
                    </Stack>
                )
            }
        </Stack >
    )
}

export default NewsContainer
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

import { organizationSlice } from "./organization/organization-slice";
import { userSlice } from "./user/user-slice";
import { centerSlice } from "./center/center-slice";
import { newsSlice } from "./news/news-slice";

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

const combinedReducers = combineReducers({
    ORGANIZATION: organizationSlice.reducer,
    USER: userSlice.reducer,
    CENTER: centerSlice.reducer,
    NEWS: newsSlice.reducer
})

// SET ORGANIZATION IN LOCALSTORAGE WITH REDUX-PERSIST
const persistConfig = {
    key: 'redux-persist-store',
    storage,
    whitelist: ['ORGANIZATION', 'USER']
}

const persistedReducer = persistReducer(persistConfig, combinedReducers)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
})

export const persistor = persistStore(store);
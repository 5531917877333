import { useSelector } from "react-redux";

import { CONFIG_CTO } from "../data/config.CTO"
import { RootState } from "../store/store";
import { Stack, Typography } from "@mui/material";

const Account = () => {

    document.title = CONFIG_CTO.MY_ACCOUNT

    const userState = useSelector((store: RootState) => store.USER);

    return (
        <Stack mt={2}>
            <Typography>
                {userState.currentUser?.firstName} {userState.currentUser?.lastName}
            </Typography>
        </Stack>
    )
}

export default Account
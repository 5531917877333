import { AxiosResponse } from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { api } from "../../utils/api";
import { CONFIG_API } from "../../data/config.API";
import { AppDispatch } from "../store";
import { OrganizationModel } from "../../models/OrganizationModel";
import { APIResponseModel, APIResponsesModel } from "../../models/ApiResponseModel";

export interface OrganizationListModel {
  code: string,
  pid: string,
  name: string
}

export interface InitialStateModel {
  userOrganizations: Array<OrganizationListModel> | undefined
  currentOrganization: OrganizationModel | undefined | null
  loading: boolean;
  error: string | null;
}

const initialState: InitialStateModel = {
  userOrganizations: undefined,
  currentOrganization: undefined,
  loading: true,
  error: null
}

// SLICE
export const organizationSlice = createSlice({
  name: "organizationSlice",
  initialState: initialState,
  reducers: {
    setUserOrganizations: (currentSlice, action) => {
      currentSlice.userOrganizations = action.payload
    },
    setCurrentOrganization: (currentSlice, action) => {
      currentSlice.currentOrganization = action.payload
    },
    setLoading: (currentSlice, action) => {
      currentSlice.loading = action.payload
    },
    setError: (currentSlice, action) => {
      currentSlice.error = action.payload
    }
  }
});

export const { setUserOrganizations, setCurrentOrganization, setLoading, setError } = organizationSlice.actions;

// ACTIONS
export const fetchOrganizations = (externalUserId?: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  try {
    await api.get(`/${CONFIG_API.CTONLINE_ADMINISTRATION}/${CONFIG_API.USER}/${externalUserId}/${CONFIG_API.ORGANIZATION}`).then((response: AxiosResponse<APIResponsesModel<OrganizationListModel>>) => {
      dispatch(setLoading(false));
      const organizationsNumber = response.data.items.length
      if (organizationsNumber === 0) {
        dispatch(setError("Aucune organisation associée à l'utilisateur"))
      } else if (organizationsNumber === 1) {
        dispatch(fetchOrganizationData(response.data.items[0].pid));
      } else {
        dispatch(setUserOrganizations(response.data.items));
      }
    })
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setError(error))
  }
}

export const fetchOrganizationData = (organizationPid: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  try {
    await api.get(`/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}`).then((response: AxiosResponse<APIResponseModel<OrganizationModel>>) => {
      dispatch(setLoading(false));
      dispatch(setCurrentOrganization(response.data.item));
    })
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setError(error))
  }
}
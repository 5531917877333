import { ReactNode } from "react"
import { Grow, Modal, Paper, Stack } from "@mui/material";

import CTonlineLogo from "../../assets/images/ctonline-small-logo.png";

type Props = {
    open: boolean,
    showLogo?: boolean,
    width?: { xs: string | number, sm: string | number }
    height?: string | number
    gap?: string | number
    padding?: string | number
    children: ReactNode,
}

const CustomModal = ({ children, open, showLogo = true, gap = 3, padding = 0, width = { xs: "20rem", sm: "30rem" }, height = "15rem" }: Props) => {

    return (
        <Modal
            open={open}
        >
            <Stack height={"100vh"} justifyContent={"center"} alignItems={"center"} >
                <Grow in={open}>
                    <Paper
                        sx={{
                            width: width,
                            height: height,
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: gap,
                            padding: padding,
                        }}
                    >
                        {showLogo && (
                            <img src={CTonlineLogo} width={175} alt="Icône CTonline" />
                        )}
                        {children}
                    </Paper>
                </Grow>
            </Stack>
        </Modal>)
}

export default CustomModal
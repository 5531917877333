import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Badge, Divider, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faQuestion, faBagShopping, faBell, faSitemap, faHouse, faMessageQuestion } from '@fortawesome/pro-light-svg-icons'

import { RootState } from '../../store/store';
import { MenuItemModel } from '../common/BasicMenuItem';
import { CenterModel } from '../../models/CenterModel';
import { HeaderProps } from './Header';
import { CONFIG_API } from '../../data/config.API';
import { CONFIG_CTO } from '../../data/config.CTO';
import packageJson from '../../../package.json'

import BasicMenu from '../common/BasicMenu';

const SettingsList = ({ showDrawer, setShowDrawer, unreadNewsNumber }: HeaderProps) => {

    const [iconsAnimation, setIconsAnimation] = useState<{ [key: string]: boolean }>({
        faGearIcon: false,
        faQuestionIcon: false,
        faBagShoppingIcon: false,
        faBellIcon: false,
    });

    const toggleIconAnimation = (iconName: string, isAnimated: boolean) => {
        setIconsAnimation({ ...iconsAnimation, [iconName]: isAnimated });
    }

    const currentUserState = useSelector((store: RootState) => store.USER.currentUser);
    const centersList = useSelector((store: RootState) => store.CENTER.organizationCenters);
    const currentOrganizationState = useSelector((store: RootState) => store.ORGANIZATION.currentOrganization);

    const settingMenuItems: MenuItemModel[] = []

    // Add "organization parameters" to menu if they have access to price or customer settings
    if (currentUserState?.hasPriceSettingsAccess || currentUserState?.hasCustomerSettingsAccess) {
        settingMenuItems.push({
            name: "organizationParameters",
            text: currentOrganizationState?.name ?? "",
            icon: faSitemap,
            navigateTo: (`/${CONFIG_CTO.ORGANIZATION_PATH}`)
        })
    }
    // If the current user is not restricted and has access to center settings, add the specific 'center parameter' item to the menu for each center that is neither closed nor limited
    if (!currentUserState?.isCTonlineLimited && currentUserState?.hasCenterSettingsAccess) {
        centersList?.forEach((center: CenterModel) => {
            !center.isClosed && !center.isLimited && (
                settingMenuItems.push({
                    name: center.centerCode,
                    text: center.name,
                    icon: faHouse,
                    navigateTo: (`/${CONFIG_CTO.CENTER_PATH}`),
                    locationState: center.centerId
                })
            )
        })
    }

    const helpMenuItems: MenuItemModel[] = [
        {
            name: "Aide",
            text: "Centre d'aide",
            icon: faMessageQuestion,
            externalLink: "https://protechnologies.zendesk.com/"
        }
    ]

    return (
        <>
            {currentUserState?.accountType !== CONFIG_API.CLIENT ? (
                <Stack flexDirection={"row"} gap={1.5}>
                    {(currentUserState?.hasCenterSettingsAccess || currentUserState?.hasCustomerSettingsAccess || currentUserState?.hasPriceSettingsAccess) && (
                        <BasicMenu
                            positionY={38}
                            positionX={-150}
                            items={settingMenuItems}
                            color="inherit"
                            isIcon
                        >
                            <FontAwesomeIcon icon={faGear} size="xl" title='Réglages' style={{ cursor: "pointer", animationDuration: "0.5s", animationIterationCount: 1 }} beat={iconsAnimation.faGearIcon} onMouseEnter={() => toggleIconAnimation("faGearIcon", true)} onMouseLeave={() => toggleIconAnimation("faGearIcon", false)} />
                        </BasicMenu>
                    )}
                    <Stack display={{ xs: "none", md: "flex" }} flexDirection={"row"} gap={2}>
                        <BasicMenu
                            positionY={38}
                            positionX={-75}
                            items={helpMenuItems}
                            afterText={
                                <Stack gap={"0.1rem"}>
                                    <Divider />
                                    <Typography padding="0.5rem 0 0" textAlign={"end"} fontSize={"0.8rem"}>Version {packageJson.version}</Typography>
                                </Stack>}
                            isIcon
                        >
                            <FontAwesomeIcon icon={faQuestion} size="xl" title={CONFIG_CTO.HELP} style={{ cursor: "pointer", animationDuration: "0.5s", animationIterationCount: 1 }} beat={iconsAnimation.faQuestionIcon} onMouseEnter={() => toggleIconAnimation("faQuestionIcon", true)} onMouseLeave={() => toggleIconAnimation("faQuestionIcon", false)} />
                        </BasicMenu>
                        <a href="https://protechnologies.shop/" target='_blank' rel='noopener noreferrer'>
                            <FontAwesomeIcon icon={faBagShopping} size="xl" title={CONFIG_CTO.SHOP} style={{ cursor: "pointer", animationDuration: "0.5s", animationIterationCount: 1 }} beat={iconsAnimation.faBagShoppingIcon} onMouseEnter={() => toggleIconAnimation("faBagShoppingIcon", true)} onMouseLeave={() => toggleIconAnimation("faBagShoppingIcon", false)} />
                        </a>
                        <Badge
                            invisible={unreadNewsNumber < 1}
                            badgeContent={unreadNewsNumber}
                            color="warning"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        >
                            <FontAwesomeIcon icon={faBell} size="xl" title='Actualités' style={{ cursor: "pointer", animationDuration: "0.5s", animationIterationCount: 1 }} beat={iconsAnimation.faBellIcon} onMouseEnter={() => toggleIconAnimation("faBellIcon", true)} onMouseLeave={() => toggleIconAnimation("faBellIcon", false)} onClick={() => setShowDrawer(!showDrawer)} />
                        </Badge>
                    </Stack>
                </Stack>
            ) : null}
        </>
    )

}

export default SettingsList
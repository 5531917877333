import { Stack, Typography } from '@mui/material'

import ErrorScreenIcon from "../../assets/images/icons/error-screen.png";
import ErrorCrossIcon from "../../assets/images/icons/error-cross.png";
import ErrorUnauthorized from "../../assets/images/icons/unauthorized.png";
import LogoutButton from '../common/LogoutButton';


type Props = {
    variant?: "error" | "forbidden" | "unknown" | "iframeDisplay",
    showLogoutButton?: boolean
    displayMessage?: string
    displayName?: string,
}

const ErrorDisplay = ({ variant = "error", showLogoutButton = false, displayMessage = "Une erreur est survenue.", displayName = "le contenu de la page" }: Props) => {

    let icon: string = "";
    let message: string = displayMessage;
    let alt: string = "";

    switch (variant) {
        case "forbidden":
            icon = ErrorUnauthorized;
            message = "Vous n'êtes pas autorisé à accéder à cette page.";
            alt = "Accès Interdit";
            break;
        case "unknown":
            icon = ErrorScreenIcon;
            message = "Oups, cette page n'existe pas.";
            alt = "Page Introuvable";
            break;
        case "iframeDisplay":
            icon = ErrorScreenIcon;
            message = `Impossible d'afficher ${displayName}.`;
            alt = "Erreur d'Affichage";
            break;
        default:
            icon = ErrorCrossIcon;
            alt = "Erreur";
            break;
    }

    return (
        <Stack margin="auto" gap={3}>
            <img src={icon} style={{ margin: "auto" }} width={80} alt={alt} />
            <Typography color="primary.main" variant='h6' fontWeight="bold">{message}</Typography>
            {showLogoutButton && (
                <LogoutButton width={"auto"} />
            )}
        </Stack>
    )
}

export default ErrorDisplay
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { RootState } from '../store/store'
import { CONFIG_CTO } from '../data/config.CTO'

const Home = () => {

    const currentOrganizationState = useSelector((store: RootState) => store.ORGANIZATION.currentOrganization)

    // IF USER IS LIMITED REDIRECT TO DOCUMENTS, ELSE REDIRECT TO CALENDAR
    return !currentOrganizationState?.isCTonlineLimited ? (
        <Navigate to={`/${CONFIG_CTO.CALENDAR_PATH}`} />
    ) : (
        <Navigate to={`/${CONFIG_CTO.DOCUMENTS_PATH}`} />
    )
}

export default Home
import { useState, useEffect, useCallback, ReactNode } from 'react'
import { Location, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Stack, Typography } from '@mui/material';
import { useMsal } from '@azure/msal-react'

import { AppDispatch, RootState } from '../../store/store';
import { fetchOrganizationData, fetchOrganizations, setCurrentOrganization } from '../../store/organization/organization-slice';
import { fetchUserData } from '../../store/user/user-slice';
import { fetchUserCenters } from '../../store/center/center-slice';
import { api } from '../../utils/api';
import { CONFIG_CTO } from '../../data/config.CTO';
import { CONFIG_API } from '../../data/config.API';
import { AccountType } from '../../models/UserModel';
import { APIResponseModel } from '../../models/ApiResponseModel';
import { AxiosResponse } from 'axios';
import isSuperAdministrator from '../../utils/authorization/isSuperAdministrator';
import useAuthCheck from '../../hooks/authorization/useAuthCheck';
import ErrorUnauthorized from "../../assets/images/icons/unauthorized.png";
import CTonlineLogo from "../../assets/images/ctonline-small-logo.png";

import CustomModal from './CustomModal';
import OrganizationForm from './OrganizationForm';
import OrganizationSelector from './OrganizationSelector';
import ErrorDisplay from './ErrorDisplay';
import LogoutButton from '../common/LogoutButton';
import ConnectCheck from '../../pages/connect/ConnectCheck';
import ListSelector from './ListSelector';

type Props = {
    children: ReactNode
}

const AuthenticationPage = ({ children }: Props) => {

    useAuthCheck()
    const { inProgress, accounts, instance } = useMsal();
    const dispatch: AppDispatch = useDispatch();
    const location: Location = useLocation();

    const [isUserAdministrator, setIsUserAdministrator] = useState<boolean | undefined>(undefined)
    const [showOrganizationSelectorModal, setShowOrganizationSelectorModal] = useState<boolean>(true)
    const [showAdminOrganizationModal, setShowAdminOrganizationModal] = useState<boolean>(false)
    const organizationState = useSelector((store: RootState) => store.ORGANIZATION);
    const userState = useSelector((store: RootState) => store.USER);
    const centerState = useSelector((store: RootState) => store.CENTER)
    const isLoading = {
        organizationState: useSelector((store: RootState) => store.ORGANIZATION.loading),
        userState: useSelector((store: RootState) => store.USER.loading),
        centerState: useSelector((store: RootState) => store.CENTER.loading)
    }
    let isError: null | { organizationState: null | string, userState: null | string, centerState: null | string } =
    {
        organizationState: useSelector((store: RootState) => store.ORGANIZATION.error),
        userState: useSelector((store: RootState) => store.USER.error),
        centerState: useSelector((store: RootState) => store.CENTER.error)
    }

    const handleLogout = async () => {
        localStorage.clear(); // TO CLEAR ORGANIZATION SETTED WITH REDUX-PERSIST
        await instance.logout({
            authority: process.env.REACT_APP_AZURE_BASE_URL,
            postLogoutRedirectUri: `/`,
        });
    };

    // GET USER'S INFORMATION FROM ORGANIZATION PID & EXTERNAL USER ID
    const getUserData = useCallback(async () => {
        if (organizationState.currentOrganization?.pid) {
            dispatch(fetchUserData(organizationState.currentOrganization?.pid, accounts[0]?.idTokenClaims?.sub))
        }
    }, [dispatch, accounts, organizationState])

    // GET OPEN USER'S CENTERS
    const getUserCenters = useCallback(async (accountType: AccountType, externalUserId: string) => {
        if (organizationState.currentOrganization) {
            await dispatch(fetchUserCenters(organizationState.currentOrganization?.pid, accountType, externalUserId))
        }
    }, [dispatch, organizationState.currentOrganization])

    // GET USER'S ORGANIZATION FROM EXTERNAL USER ID (AZURE)
    const getData = useCallback(async () => {
        if (accounts[0] && isUserAdministrator === undefined) {
            setIsUserAdministrator(await isSuperAdministrator(accounts[0]?.idTokenClaims?.sub));
        }
        if (isUserAdministrator === false) {
            await dispatch(fetchOrganizations(accounts[0]?.idTokenClaims?.sub));
        } else if (isUserAdministrator === true && !organizationState.currentOrganization) {
            // Checks for organizationPid in URL parameters in case of redirection
            const params = new URLSearchParams(window.location.search);
            const URLOrganizationPid = params.get('organizationPid');
            if (URLOrganizationPid) {
                dispatch(fetchOrganizationData(URLOrganizationPid)) // If coming from a redirection, set the organization to avoid declaring it a second time (as the organization is reset before redirection)
            } else {
                setShowAdminOrganizationModal(true)
            }
        }
    }, [dispatch, isUserAdministrator])

    const handleEarlyAccess = async () => {
        const currentUrl = window.location.origin;
        if (currentUrl.includes("localhost")) return;

        // Check if user has to be redirected to early acess URL (if an early access is currently accessible) or to standard URL
        try {
            const headResponse = await api.head(`${CONFIG_API.CTONLINE_ADMINISTRATION}/SoftwareVersion/IsEarlyAccessAvailable`);
            const isEarlyAccessAvailable = headResponse.status === 200; // or headResponse.status !== 404 depending on API behavior
            const { data: { item: hasOrganizationEarlyAccess } } = await api.get(`${CONFIG_API.CTONLINE_ADMINISTRATION}/${CONFIG_API.ORGANIZATION}/${organizationState.currentOrganization?.pid}/HasEarlyAccess`);

            const currentOrganizationPid = `&organizationPid=${organizationState.currentOrganization?.pid}`;
            const userIdToken = `?userPid=${accounts[0].idTokenClaims?.sub}${currentOrganizationPid}`;
            const targetUrl = isEarlyAccessAvailable && hasOrganizationEarlyAccess ? process.env.REACT_APP_WEB_EARLYACCESS_BASE_URL : process.env.REACT_APP_WEB_BASE_URL;

            if ((isEarlyAccessAvailable && hasOrganizationEarlyAccess && currentUrl !== process.env.REACT_APP_WEB_EARLYACCESS_BASE_URL) ||
                (!isEarlyAccessAvailable || !hasOrganizationEarlyAccess) && currentUrl === process.env.REACT_APP_WEB_EARLYACCESS_BASE_URL) {
                dispatch(setCurrentOrganization(null)); // Reset to avoid redirection loop
                window.location.href = targetUrl + userIdToken;
            }
        } catch (error) {
            if (currentUrl === process.env.REACT_APP_WEB_EARLYACCESS_BASE_URL) {
                dispatch(setCurrentOrganization(null)); // Reset to avoid redirection loop
                window.location.href = process.env.REACT_APP_WEB_BASE_URL + `?userPid=${accounts[0].idTokenClaims?.sub}&organizationPid=${organizationState.currentOrganization?.pid}`;
            }
        }
    }


    useEffect(() => {
        if (userState.currentUser?.accountType && userState.currentUser?.externalUserId) {
            getUserCenters(userState.currentUser.accountType, userState.currentUser.externalUserId)
        } else {
            getUserData()
        }
    }, [getUserData, getUserCenters, showAdminOrganizationModal, organizationState, userState.currentUser])

    useEffect(() => {
        // After redirection, check if still the same user : if not, logout.
        const params = new URLSearchParams(window.location.search);
        const redirectedUserPid = params.get('userPid')
        if (redirectedUserPid && accounts[0] && redirectedUserPid !== accounts[0].idTokenClaims?.sub) {
            handleLogout();
        }
        getData();
    }, [getData])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const URLOrganizationPid = params.get('organizationPid');
        if (URLOrganizationPid) {
            dispatch(setCurrentOrganization(null))
        }
        if (organizationState && organizationState.currentOrganization === null) {
            if (URLOrganizationPid) {
                dispatch(fetchOrganizationData(URLOrganizationPid)) // If coming from a redirection, set the organization to avoid declaring it a second time (as the organization is reset before redirection)
            } else {
                if (organizationState.userOrganizations && organizationState.userOrganizations?.length > 1) {
                    setShowOrganizationSelectorModal(true)
                } else {
                    if (isUserAdministrator) {
                        setShowAdminOrganizationModal(true)
                    }
                }
            }
        } else {
            organizationState.currentOrganization && handleEarlyAccess()
        }
    }, [organizationState.currentOrganization])

    return (
        // If the path is "connect-login", render ConnectCheck Component
        location.pathname === `/${CONFIG_CTO.CONNECT_CHECK_PATH}` ? (
            <ConnectCheck />
        ) : (
            accounts && accounts.length > 0 && inProgress === "none" ? (
                <>
                    {/* IF USER IS SUPERADMIN : SHOW ORGANIZATION TEXT INPUT MODAL */}
                    <CustomModal open={showAdminOrganizationModal} height={"auto"} padding={2}>
                        <OrganizationForm setShowModal={setShowAdminOrganizationModal} />
                    </CustomModal>
                    {/* IF USER HAS SEVERAL ORGANIZATIONS : SHOW SELECT ORGANIZATION MODAL */}
                    {!organizationState.currentOrganization && organizationState.userOrganizations && organizationState.userOrganizations.length > 1 && (
                        <CustomModal open={showOrganizationSelectorModal} height={"auto"} padding={2}>
                            <OrganizationSelector setShowModal={setShowOrganizationSelectorModal} organizationsList={organizationState.userOrganizations} />
                        </CustomModal>
                    )}
                    {/* IF USER HAS SEVERAL PROFILES : SHOW SELECT PROFILE MODAL */}
                    {!userState.currentUser && userState.usersList && userState.usersList.length > 1 && (
                        <CustomModal open={true} height={"auto"} padding={2}>
                            <ListSelector variant="profileSelect" arrayList={userState.usersList} label="Choisissez un profil client" />
                            <LogoutButton width={"auto"} />
                        </CustomModal>
                    )}
                    {/* ONCE AN ORGANIZATION IS SETTED, SHOW APP CONTENT IF USER ISN'T LIMITED*/}
                    {organizationState.currentOrganization && userState.currentUser && centerState.organizationCenters ? (
                        organizationState.currentOrganization.isCTonlineLimited && userState.currentUser.accountType !== CONFIG_API.ADMIN ? (
                            <CustomModal open={true} height={"auto"} padding={2}>
                                <Stack gap={2}>
                                    <img src={ErrorUnauthorized} style={{ margin: "auto" }} width={80} alt={"Accès non autorisé"} />
                                    <Typography fontWeight="bold" sx={{ color: "primary.main" }}>
                                        Vous n'êtes pas autorisé à accéder à CTonline.<br />
                                        Veuillez vous connecter avec un compte administrateur.
                                    </Typography>
                                    <LogoutButton width={"auto"} />
                                </Stack>
                            </CustomModal>
                        ) : (
                            children
                        )
                    ) : (!isLoading.organizationState && isError.organizationState !== null && JSON.stringify(isError.organizationState) !== '{}') || (!isLoading.userState && isError.userState !== null && JSON.stringify(isError.userState) !== '{}') || (!isLoading.centerState && isError.centerState !== null && JSON.stringify(isError.centerState) !== '{}') ? (
                        <>
                            {console.error(isError)}
                            <Stack width={"50%"} height={"100vh"} alignItems={"center"} m="auto">
                                <Stack m="auto" gap={5}>
                                    <img src={CTonlineLogo} width={175} alt="Icône CTonline" style={{ margin: "auto" }} />
                                    <ErrorDisplay displayMessage={"Votre compte n'a pas accès à CTonline."} showLogoutButton />
                                </Stack>
                            </Stack>
                        </>
                    ) : isLoading.organizationState || isLoading.userState || isLoading.centerState ? (
                        <Stack height={"100vh"} >
                            <Stack m="auto" gap={2} alignItems={"center"}>
                                <CircularProgress />
                                <Typography variant='h6' fontWeight="bold" color="primary.main">Chargement des données...</Typography>
                                <LogoutButton width="auto" />
                            </Stack>
                        </Stack>
                    ) : (
                        null
                    )}
                </>
            ) : (null)
        )
    )
}

export default AuthenticationPage
import { AxiosResponse } from "axios";

import { APIResponseModel } from "../../models/ApiResponseModel";
import { api } from "../api"
import { CONFIG_API } from "../../data/config.API";

type AdminModel = {
    externalUserId: string;
    isAdministrator: boolean;
    displayName: string;
    mail: string;
}

const isSuperAdministrator = async (externalUserId: string | undefined) => {
    try {
        let response: AxiosResponse<APIResponseModel<AdminModel>> = await api.get(`/${CONFIG_API.CTONLINE_ADMINISTRATION}/${CONFIG_API.USER}/${externalUserId}`);
        if (response.data.success) {
            if (response.data.item) {
                return response.data.item.isAdministrator
            }
        }
    } catch (error) {
        console.error(error)
    }
    return false;

}

export default isSuperAdministrator
/**
 * ConnectCheck - This page is accessed from the CTo Connect application. 
 * If an user is already logged in the APP and signs in with another account in CTo Connect, the first account would be the current account
 * To avoid this case, this page compares the Azure ID Token from the current account with the one in URL params (sent from CTo Connect)
 * If tokens aren't the same, the user is logout. Otherwise, redirect to the homepage.
 */

import { useMsal } from "@azure/msal-react";
import { useCallback, useEffect } from "react";
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom"
import { CircularProgress, Stack, Typography } from "@mui/material";

import CustomModal from "../../components/layouts/CustomModal";

const ConnectCheck = () => {
    const { instance, accounts, inProgress } = useMsal()
    const navigate: NavigateFunction = useNavigate()
    const [searchParams]: [URLSearchParams, Function] = useSearchParams();

    let tokenAzure: string = searchParams.get("azure_account") ?? "";

    const handleLogout = useCallback(async () => {
        localStorage.clear(); // TO CLEAR ORGANIZATION SETTED WITH REDUX-PERSIST
        await instance.logout({
            authority: process.env.REACT_APP_AZURE_BASE_URL,
            postLogoutRedirectUri: `/`,
        });
    }, [instance])


    useEffect(() => {
        if (inProgress === "none" && accounts[0]) {
            if (accounts[0].idTokenClaims?.sub === tokenAzure) {
                navigate("/");
            } else {
                handleLogout()
            }
        }
    }, [inProgress, accounts, handleLogout, navigate, tokenAzure])

    return (
        <Stack height={"100vh"} >
            <CustomModal open={true} padding={2} width={{ xs: "100%", sm: "100%" }} height={"100vh"}>
                <CircularProgress />
                <Typography variant='h6' fontWeight="bold" color="primary.main">Vérification du compte...</Typography>
            </CustomModal>
        </Stack>
    )
}

export default ConnectCheck
import { Stack } from "@mui/material";

import ErrorDisplay from "./ErrorDisplay";

const Error404 = () => {

    return (
        <Stack height={"100vh"} >
            <ErrorDisplay variant="unknown" />
        </Stack>
    )
}

export default Error404
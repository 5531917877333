import { useSelector } from 'react-redux'
import { Stack } from '@mui/material'

import { RootState } from '../store/store'
import useIframeParams, { ParamsModel } from "../hooks/authorization/useIframeParams"
import { CONFIG_URL } from "../data/config.URL"
import { CONFIG_CTO } from "../data/config.CTO"

import ErrorDisplay from "../components/layouts/ErrorDisplay"

const Documents = () => {
    document.title = CONFIG_CTO.DOCUMENTS

    const currentUserState = useSelector((store: RootState) => store.USER.currentUser)
    const params: ParamsModel = useIframeParams()

    return (
        <Stack height="100vh" mt={"0.5rem"}>
            {currentUserState?.hasDocumentAccess ? (
                <iframe id="ctframe"
                    title="Navigation dans l'application web CTonline"
                    sandbox="allow-same-origin allow-scripts allow-downloads allow-modals allow-popups allow-forms allow-top-navigation-by-user-activation allow-top-navigation allow-popups-to-escape-sandbox"
                    src={`https://${params.organisationCode}${process.env.REACT_APP_CTONLINE_BASE_URL}/${params.organisationCode}/v2/Documents.aspx?${CONFIG_URL.ORGANIZATION_CODE}=${params.organisationCode}&${CONFIG_URL.IS_SUPERADMINISTRATOR}=${params.isProtech}&${CONFIG_URL.IS_CTONLINE_LIMITED}=${params.isLimited}&${CONFIG_URL.USERNAME}=${params.userName}&${CONFIG_URL.LOGINTYPE}=${params.loginType}&${CONFIG_URL.TIMESTAMP}=${params.connexionTimeStamp}&${CONFIG_URL.SIGNATURE}=${params.signature}`}
                    className="iframe"
                    width="100%"
                    height="100%"
                >
                    <ErrorDisplay variant="iframeDisplay" displayName={`les ${CONFIG_CTO.DOCUMENTS.toLowerCase()}`} />
                </iframe>
            ) : (
                <ErrorDisplay variant='forbidden' />
            )}
        </Stack>
    )
}

export default Documents
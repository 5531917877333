import { useState, useEffect } from 'react'

const useWindowWidth = () => {

    const [isDesktop, setDesktop] = useState(false);

    useEffect(() => {
        if (window.innerWidth > 900) {
            setDesktop(true);
        } else {
            setDesktop(false);
        }

        const updateMedia = () => {
            if (window.innerWidth > 900) {
                setDesktop(true);
            } else {
                setDesktop(false);
            }
        };
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    }, []);

    return isDesktop;
}

export default useWindowWidth
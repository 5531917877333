import { useState, useEffect } from 'react';

const useDateFormat = (stringedDate: string): string => {
    const [formattedDate, setFormattedDate] = useState('');

    useEffect(() => {
        // CREATE DATE OBJECT WITH THE DATE PARAMETER
        const date = new Date(stringedDate);

        const monthsList: string[] = [
            "janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"
        ];

        // EXTRACT DAY, MONTH AND YEAR
        const day: number = date.getDate();
        const month: number = date.getMonth();
        const year: number = date.getFullYear();

        // FORMAT DATE IN THE NEW FORMAT
        const formattedDate: string = `${day} ${monthsList[month]} ${year}`;

        setFormattedDate(formattedDate);
    }, [stringedDate]);

    return formattedDate;
}

export default useDateFormat;
// @ts-nocheck
import axios, { AxiosInstance } from "axios";

import { PCA, scopes } from "../msal/msal";

let api: AxiosInstance;
const accounts = PCA.getAllAccounts();

if (accounts[0]) {
  try {
    await PCA.acquireTokenSilent({
      scopes: scopes,
      account: accounts[0],
    }).then((accessTokenResponse) => {
      const accessToken = accessTokenResponse.accessToken;
      if (accessToken) {
        api = axios.create({
          baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
          headers: {
            "Content-Type": "application/json",
            "X-Version": `${process.env.REACT_APP_API_VERSION}`,
            Authorization: `Bearer ${accessToken}`,
          },
        })
      }
    })
  } catch (error) {
    localStorage.clear(); // TO CLEAR ORGANIZATION SETTED WITH REDUX-PERSIST
    PCA.logoutRedirect({
      authority: process.env.REACT_APP_AZURE_BASE_URL,
      postLogoutRedirectUri: `/`,
    });
  }
}

export { api };
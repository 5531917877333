import { useSelector } from "react-redux"
import { Stack } from "@mui/material"

import useIframeParams, { ParamsModel } from "../hooks/authorization/useIframeParams"
import { RootState } from "../store/store"
import { CONFIG_URL } from "../data/config.URL"
import { CONFIG_CTO } from "../data/config.CTO"

import ErrorDisplay from "../components/layouts/ErrorDisplay"

const Dashboards = () => {
    document.title = CONFIG_CTO.DASHBOARDS
    const currentOrganizationState = useSelector((store: RootState) => store.ORGANIZATION.currentOrganization)
    const params: ParamsModel = useIframeParams();

    return (
        <Stack height="100vh" mt={"0.5rem"}>
            {!currentOrganizationState?.isCTonlineLimited ? (
                <iframe id="ctframe"
                    title="Navigation dans l'application web CTonline"
                    sandbox="allow-same-origin allow-scripts allow-downloads allow-modals allow-popups allow-forms allow-top-navigation-by-user-activation allow-top-navigation allow-popups-to-escape-sandbox"
                    src={`https://${params.organisationCode}${process.env.REACT_APP_CTONLINE_BASE_URL}/${params.organisationCode}/v2/TableauDeBord.aspx?${CONFIG_URL.ORGANIZATION_CODE}=${params.organisationCode}&${CONFIG_URL.IS_SUPERADMINISTRATOR}=${params.isProtech}&${CONFIG_URL.IS_CTONLINE_LIMITED}=${params.isLimited}&${CONFIG_URL.USERNAME}=${params.userName}&${CONFIG_URL.LOGINTYPE}=${params.loginType}&${CONFIG_URL.TIMESTAMP}=${params.connexionTimeStamp}&${CONFIG_URL.SIGNATURE}=${params.signature}`}
                    className="iframe"
                    width="100%"
                    height="100%">
                    <ErrorDisplay variant="iframeDisplay" displayName={`les ${CONFIG_CTO.DASHBOARDS.toLowerCase()}`} />
                </iframe>
            ) : (
                <ErrorDisplay variant='forbidden' />
            )
            }
        </Stack>)
}

export default Dashboards
import { createTheme } from "@mui/material";

const customTheme = createTheme({
    palette: {
        primary: { main: "#ea6927" },
        secondary: { main: "rgba(70, 47, 87, 0.85)" },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

export default customTheme;
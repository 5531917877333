import { useMsal } from '@azure/msal-react';
import { Button } from '@mui/material'

interface Props {
    width?: string | number,
    buttonVariant?: "contained" | "text" | "outlined"
}

const LogoutButton = ({ width = "50%", buttonVariant = "contained" }: Props) => {
    const { instance } = useMsal();

    const handleLogout = async () => {
        localStorage.clear(); // TO CLEAR ORGANIZATION SETTED WITH REDUX-PERSIST
        await instance.logout({
            authority: process.env.REACT_APP_AZURE_BASE_URL,
            postLogoutRedirectUri: `/`,
        });
    };
    return (
        <Button variant={buttonVariant} onClick={handleLogout} sx={{ m: "auto", width: width }}>Se déconnecter</Button>
    )
}

export default LogoutButton
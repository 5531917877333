import { useSelector } from 'react-redux'
import { Stack } from '@mui/material'
import { Location, Navigate, useLocation } from 'react-router-dom'

import { RootState } from '../store/store'
import useIframeParams, { ParamsModel } from "../hooks/authorization/useIframeParams"
import { CONFIG_URL } from "../data/config.URL"
import { CONFIG_CTO } from '../data/config.CTO'

import ErrorDisplay from '../components/layouts/ErrorDisplay'
import CustomModal from '../components/layouts/CustomModal'
import ListSelector from '../components/layouts/ListSelector'
import { CenterModel } from '../models/CenterModel'

const Center = () => {

    document.title = 'Paramètres du centre'

    const location: Location = useLocation();

    const currentUserState = useSelector((store: RootState) => store.USER.currentUser)
    const currentOrganizationState = useSelector((store: RootState) => store.ORGANIZATION.currentOrganization)
    const centersList = useSelector((store: RootState) => store.CENTER.organizationCenters)
    const params: ParamsModel = useIframeParams()
    return (
        <Stack height="100vh" mt={"0.5rem"}>
            {!currentUserState?.hasCenterSettingsAccess || currentOrganizationState?.isCTonlineLimited ? (
                <ErrorDisplay variant='forbidden' />
            ) : (
                location.state ? (
                    <iframe id="ctframe"
                        title="Navigation dans l'application web CTonline"
                        sandbox="allow-same-origin allow-scripts allow-downloads allow-modals allow-popups allow-forms allow-top-navigation-by-user-activation allow-top-navigation allow-popups-to-escape-sandbox"
                        src={`https://${params.organisationCode}${process.env.REACT_APP_CTONLINE_BASE_URL}/${params.organisationCode}/v2/ParametresCentre.aspx?Ctr=${location.state}&${CONFIG_URL.ORGANIZATION_CODE}=${params.organisationCode}&${CONFIG_URL.IS_SUPERADMINISTRATOR}=${params.isProtech}&${CONFIG_URL.IS_CTONLINE_LIMITED}=${params.isLimited}&${CONFIG_URL.USERNAME}=${params.userName}&${CONFIG_URL.LOGINTYPE}=${params.loginType}&${CONFIG_URL.TIMESTAMP}=${params.connexionTimeStamp}&${CONFIG_URL.SIGNATURE}=${params.signature}`}
                        className="iframe"
                        width="100%"
                        height="100%">
                        <ErrorDisplay variant="iframeDisplay" displayName={`les réglages du centre`} />
                    </iframe>
                ) : (
                    // IF SEVERAL CENTERS: SHOW SELECTOR CENTER MODAL, ELSE PICK AUTOMATICALLY THE ONLY CENTER
                    centersList && centersList?.length > 1 ? (
                        <CustomModal open={true}>
                            <ListSelector variant="centerSelect" arrayList={centersList.filter((center: CenterModel) => !center.isClosed && !center.isLimited)} label="Choisissez un centre" />
                        </CustomModal>
                    ) : (
                        centersList && (
                            <Navigate to={`/${CONFIG_CTO.CENTER_PATH}`} state={centersList[0].centerId} />
                        )
                    )
                )
            )
            }
        </Stack>
    )
}

export default Center
export const CONFIG_API = {
    CTONLINE: "CTOnline",
    CTONLINE_ADMINISTRATION: "CTonlineAdministration",
    CTONLINE_CONNECT: "CTonlineConnect",
    ORGANIZATION: "Organization",
    CENTER: "Center",
    ACCOUNT: "Account",
    ADMIN: "Admin",
    USER: "User",
    CLIENT: "Client",
    LOGIN: "login",
    ORGANIZATION_PID: "organizationPid",
    EXTERNAL_ACCOUNT: "ExternalAccount",
    EXTERNAL_USER_ID: "ExternalUserId",
    PROFILE: "Profile",
    PASSWORD: "password",
    MAIL: "mail",
    NEWS: "News",
    MESSAGE: "Message",
    VL: "VL",
    PL: "PL",
    VL_PL: "VL|PL"
}
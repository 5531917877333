import * as React from 'react';
import { Button, Menu, Stack } from '@mui/material';

import BasicMenuItem, { MenuItemModel } from "./BasicMenuItem";

type Props = {
    children: React.ReactNode,
    isIcon?: boolean,
    items: MenuItemModel[],
    positionY: number | 'bottom' | 'center' | 'top',
    positionX: number | 'left' | 'center' | 'right',
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning",
    beforeText?: React.JSX.Element
    afterText?: React.JSX.Element
}

const BasicMenu = ({ children, positionY, positionX, items, beforeText, afterText, isIcon = false, color = "primary" }: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            {!isIcon ? (
                <Button
                    color={color}
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    {children}
                </Button>
            ) : (
                <Stack
                    color={color}
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    {children}
                </Stack>
            )
            }
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: positionY,
                    horizontal: positionX,
                }}
            >
                {beforeText && (
                    <Stack p={"0 0.5rem"}>
                        {beforeText}
                    </Stack>
                )}
                {items.map((item: MenuItemModel) =>
                    <BasicMenuItem key={items.indexOf(item)} type={item.type} icon={item.icon} text={item.text} setAnchorEl={setAnchorEl} navigateTo={item.navigateTo} locationState={item.locationState} onClick={item.onClick} externalLink={item.externalLink} />
                )}
                {afterText && (
                    <Stack p={"0 0.5rem"}>
                        {afterText}
                    </Stack>
                )}
            </Menu>
        </div>
    );
}

export default BasicMenu;
import { Stack } from "@mui/material"
import { useState, useEffect } from "react"
import { useSelector } from "react-redux"

import { RootState } from "../store/store"
import useIframeParams, { ParamsModel } from "../hooks/authorization/useIframeParams"
import { CONFIG_CTO } from "../data/config.CTO"
import { CONFIG_URL } from "../data/config.URL"

import ErrorDisplay from "../components/layouts/ErrorDisplay"

const Calendar = () => {
    document.title = CONFIG_CTO.CALENDAR
    const currentOrganizationState = useSelector((store: RootState) => store.ORGANIZATION.currentOrganization)
    const params: ParamsModel = useIframeParams();
    const [iframe, setIframe] = useState<null | HTMLIFrameElement>(null);

    useEffect(() => {
        if (!iframe) {
            setIframe(document.getElementById('ctframe') as HTMLIFrameElement)
        }
        const handleMessage = (event: MessageEvent) => {
            if (iframe && event.data) {
                if (event.data.type === 'BURGER_MENU_CLICKED') {
                    iframe.contentWindow?.postMessage('executeOpenMenuMobile', `*`);
                }
            }
        };
        window.addEventListener('message', handleMessage);
    }, [iframe]);

    return (
        <Stack height="100vh" mt={"1.3rem"}>
            {!currentOrganizationState?.isCTonlineLimited ? (
                <iframe id="ctframe"
                    title="Navigation dans l'application web CTonline"
                    sandbox="allow-same-origin allow-scripts allow-downloads allow-modals allow-popups allow-forms allow-top-navigation-by-user-activation allow-top-navigation allow-popups-to-escape-sandbox"
                    src={`https://${params.organisationCode}${process.env.REACT_APP_CTONLINE_BASE_URL}/${params.organisationCode}/v2/Agenda.aspx?${CONFIG_URL.ORGANIZATION_CODE}=${params.organisationCode}&${CONFIG_URL.IS_SUPERADMINISTRATOR}=${params.isProtech}&${CONFIG_URL.IS_CTONLINE_LIMITED}=${params.isLimited}&${CONFIG_URL.USERNAME}=${params.userName}&${CONFIG_URL.LOGINTYPE}=${params.loginType}&${CONFIG_URL.TIMESTAMP}=${params.connexionTimeStamp}&${CONFIG_URL.SIGNATURE}=${params.signature}`}
                    className="iframe"
                    width="100%"
                    height="100%"
                >
                    <ErrorDisplay variant="iframeDisplay" displayName={`l'${CONFIG_CTO.CALENDAR.toLowerCase()}`} />
                </iframe>
            ) : (
                <ErrorDisplay variant='forbidden' />
            )
            }
        </Stack>
    )
}

export default Calendar
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { Box, Button, ListItemIcon, ListItemText, MenuItem, Stack, Typography } from '@mui/material'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export type MenuItemModel = {
    name?: string
    type?: "text" | "button"
    icon: IconProp
    variantButton?: "outlined" | "contained" | "text"
    text: string,
    setAnchorEl?: React.Dispatch<React.SetStateAction<null | HTMLElement>>
    onClick?: Function
    navigateTo?: string
    externalLink?: string
    locationState?: string | number | null
    condition?: () => boolean | undefined
}

const BasicMenuItem = ({ text, icon, onClick, navigateTo, externalLink, setAnchorEl, locationState = null, variantButton = "outlined", type = "text" }: MenuItemModel) => {

    const navigate: NavigateFunction = useNavigate()

    const handleClick = () => {
        if (navigateTo) {
            navigate(navigateTo, { state: locationState })
        } else if (onClick) {
            onClick()
        } else if (externalLink) {
            window.open(
                externalLink,
                '_blank'
            );
        }
        if (setAnchorEl) {
            setAnchorEl(null)
        }
    }

    return (
        <MenuItem onClick={handleClick}>
            {type === "text" ? (
                <Stack width={"100%"} direction="row" >
                    <ListItemIcon>
                        <Box color={"primary.main"} >
                            <FontAwesomeIcon icon={icon} size="lg" />
                        </Box>
                    </ListItemIcon>
                    <ListItemText>
                        <Typography>{text}</Typography>
                    </ListItemText>
                </Stack>
            ) : type === "button" ? (
                <Button variant={variantButton} >
                    <FontAwesomeIcon icon={icon} size="6x" color="primary.main" />
                    {text}
                </Button>
            ) : null
            }
        </MenuItem >
    )
}

export default BasicMenuItem
export const CONFIG_CTO = {
    CALENDAR: "Agenda",
    DASHBOARDS: "Tableaux de bord",
    DOCUMENTS: "Documents",
    QUALITY: "Qualité",
    TOOLS: "Outils",
    FLEET: "Flotte",
    MY_ACCOUNT: "Mon compte",
    CHANGE_ORGANIZATION: "Changer d'organisation",
    CHANGE_PROFILE: "Changer de profil client",
    SHOP: "Boutique",
    HELP: "Aide",
    LOGOUT: "Déconnexion",
    // PATHS
    CALENDAR_PATH: "calendar",
    DASHBOARDS_PATH: "dashboards",
    DOCUMENTS_PATH: "documents",
    TOOLS_PATH: "tools",
    FLEET_PATH: "fleet",
    ACCOUNT_PATH: "account",
    ORGANIZATION_PATH: "organization",
    CENTER_PATH: "center",
    CONNECT_CHECK_PATH: "connect-login"
}